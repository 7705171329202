/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Menu from "@mui/material/Menu";

/*PERSONAL COMPONENT*/
import Modal from "../common_modal/Modal";
import SpinnerSimpleBlue from "../common_spinner/SpinnerSimpleBlue";
import PINNED_BUTTON from "./PINNED_BUTTON";
import EDIT_PROJECT from "./EDIT_PROJECT";
import DeleteProject from "./DeleteProject";
import PushMember from "./PushMember";
import MOVE_PROJECT_TO_FOLDER from "./MOVE_PROJECT_TO_FOLDER";

/*REDUX FUNCTION*/
import {
  getProjectList,
  setProject,
  set_value_project,
} from "../../App/actions/projectActions";
import { createEmpty } from "../../App/actions/blogAction";
import { openModal } from "../../App/actions/authActions";

/*PICTURE ASSET*/
import icon_map_editor from "../../Assets/svg/icon_map_editor.svg";
import icon_analyze_lite from "../../Assets/svg/icon_analyze_lite.svg";

/*GENERAL FUNCTION & DATA*/
import getStatusMember from "../../App/validation/get-status-member";
import formatDate from "../../App/validation/format-date";
import dict from "../../Data/dict.json";

/*NON IMPORT*/

class PROJECT_MODE_BOX extends Component {
  state = {
    modal_menu: false,
    modal_edit: false,
    modal_delete: false,
    modal_push_member: false,
    modal_move_folder: false,
    geo_project_id: "",
    sort_by: "date",
    sort_mode: "desc",
    skip: 0,
    limit: 6,
    status_user: "",
    anchor: null,
  };

  componentDidMount() {
    const screenW = window.screen.width;
    let limit = 6;
    if (screenW > 1919) {
      limit = 15;
    } else if (screenW > 1499) {
      limit = 12;
    } else if (screenW > 1149) {
      limit = 9;
    }
    this.setState({ limit });
    if (this.props.project.geo_project_list.length === 0) {
      const params = {
        skip: this.state.skip,
        limit: limit,
      };
      this.props.getProjectList(params);
    }
  }

  toggle_menu = (e) => {
    e.preventDefault();
    this.setState({ modal_menu: !this.state.modal_menu });
    if (e) {
      const { user } = this.props.auth;
      const {
        geo_project_list,
        geo_project_list_child,
        folder_object_selected_list,
        geo_project_pinned_list,
      } = this.props.project;
      const { title } = this.props;
      const project_list =
        folder_object_selected_list.length === 0
          ? geo_project_list
          : geo_project_list_child;
      const geo_project_id = e?.target?.id;
      if (geo_project_id) {
        this.props.set_value_project({
          key: "project_id_selected_move",
          value: geo_project_id,
        });
      }
      let project = null;
      if (title === "Pinned") {
        project = geo_project_pinned_list.find(
          (item) => item?._id === geo_project_id
        );
      } else {
        project = project_list.find((item) => item?._id === geo_project_id);
      }
      const groups = project?.groups || [];
      const status_user = getStatusMember(groups, user?._id);
      this.setState({
        anchor: e.currentTarget,
        geo_project_id,
        status_user,
      });
      if (project) {
        this.props.setProject(project);
      }
    }
  };

  toggle_move_folder = () => {
    this.setState({
      modal_move_folder: !this.state.modal_move_folder,
      modal_menu: false,
    });
  };

  onOpenModal = (name) => {
    this.props.openModal(name);
  };

  toggle_edit_project = () => {
    this.setState({ modal_edit: !this.state.modal_edit, modal_menu: false });
  };

  toggleDelete = () => {
    this.setState({
      modal_delete: !this.state.modal_delete,
      modal_menu: false,
    });
  };

  toggle_push_member = () => {
    this.setState({
      modal_push_member: !this.state.modal_push_member,
      modal_menu: false,
    });
  };

  setGeoProject = (geo_project) => {
    this.props.setProject(geo_project);
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  set_load_more_project = () => {
    const skip = this.state.skip + this.state.limit;
    const limit = this.state.limit;
    const params = {
      skip,
      limit,
      append: true,
      lastProject: this.props.project.geo_project_list,
    };
    this.props.getProjectList(params);
    this.setState({
      skip,
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const {
      modal_edit,
      modal_delete,
      modal_push_member,
      modal_move_folder,
      anchor,
      modal_menu,
      status_user,
      geo_project_id,
    } = this.state;

    //global props
    const {
      geo_project_list,
      geo_project_list_child,
      folder_object_selected_list,
      geo_project_pinned_list,
      loading_item_array,
      loadingList,
    } = this.props.project;
    const { user } = this.props.auth;
    const { title } = this.props;
    const is_in_child_mode = folder_object_selected_list.length > 0 || false;
    const is_content_show = (!is_in_child_mode && title === "Pinned") || !title;

    //content
    let project_list_final = [];
    if (title === "Pinned") {
      project_list_final = geo_project_pinned_list || [];
    } else if (is_in_child_mode) {
      project_list_final = geo_project_list_child;
    } else {
      project_list_final = geo_project_list;
    }

    let content;

    if (
      project_list_final.length === 0 &&
      (loading_item_array.includes("get_project_list_by_folder_id") ||
        loadingList)
    ) {
      content = (
        <SpinnerSimpleBlue
          width={200}
          unik="loading_item_list"
          marginTop="0px"
          center={true}
          border={0.1}
        />
      );
    } else if (project_list_final.length === 0) {
      content = (
        <section
          className="container_folder outline_red"
          style={{ height: "37px", position: "relative" }}
        >
          <p className="center_child text_medium">
            {dict?.["There are no projects"]?.[language]}
          </p>
        </section>
      );
    } else {
      content = (
        <main>
          {project_list_final.map((geo_project, idx) => {
            const { name, isPublic, link, _id } = geo_project;
            const date_created = geo_project.date_created;
            const date = geo_project.date;
            const public_or_private = isPublic ? "Public" : "Private";
            const groups = geo_project?.groups || [];
            let status_user = getStatusMember(groups, user?._id);
            if (!status_user) {
              status_user = "owner";
            }
            return (
              <main
                className={`container_project ${
                  _id === geo_project_id ? "outline_blue" : "outline_grey"
                }`}
                key={idx}
                style={{ verticalAlign: "top" }}
              >
                <section>
                  <table className="full_width">
                    <tbody>
                      <tr>
                        <td className="text_left">
                          <p className="text_medium_normal max_five_lines">
                            {name}
                          </p>
                          <br />
                          <p className="text_small">
                            {date_created && (
                              <>
                                {dict["Created at"][language]}{" "}
                                {formatDate(date_created)}
                              </>
                            )}
                          </p>
                          <br />
                          <p className="text_small">
                            {date && (
                              <>
                                {dict["Modified at"][language]}{" "}
                                {formatDate(date)}
                              </>
                            )}
                          </p>
                          <br />
                          <p
                            className={`button_pill_tight margin_right margin_bottom ${
                              public_or_private === "Public"
                                ? "background_green"
                                : "background_grey"
                            }`}
                          >
                            {public_or_private}
                          </p>
                          <p
                            className={`button_pill_tight margin_right margin_bottom ${
                              [
                                "owner",
                                "creator",
                                "admin",
                                "contributor",
                              ].includes(status_user)
                                ? "background_blue"
                                : "background_grey"
                            }`}
                          >
                            {status_user}
                          </p>
                        </td>
                        <td
                          className="text_right"
                          style={{ verticalAlign: "top" }}
                        >
                          <button
                            onClick={this.toggle_menu}
                            id={geo_project?._id}
                            className="button almost_white"
                            style={{ width: "30px" }}
                            data-mapid={`${
                              title?.toLowerCase() || "basic"
                            }_${idx}_toggle`}
                          >
                            ⫶
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </section>
                <section
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    left: "0px",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <Link to={`/editor/${link}`}>
                      <div className="text_below_icon">
                        <img
                          src={icon_map_editor}
                          height="25px"
                          alt="icon"
                          className="imageStandard"
                        />
                        {dict["Map Editor"][language]}
                      </div>
                    </Link>
                    <Link to={`/view/${link}`}>
                      <div className="text_below_icon">
                        <img
                          src={icon_analyze_lite}
                          height="25px"
                          alt="icon"
                          className="imageStandard"
                        />
                        {dict["Analyze Lite"][language]}
                      </div>
                    </Link>
                  </div>
                </section>
              </main>
            );
          })}
        </main>
      );
    }

    const modal_menu_content = modal_menu && (
      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={modal_menu}
        onClose={this.toggle_menu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        style={{ borderRadius: "15px" }}
      >
        <div style={{ padding: "7px 15px 0px 15px" }}>
          {status_user === "creator" ||
          status_user === "admin" ||
          status_user === undefined ? (
            <>
              <PINNED_BUTTON toggle_menu={this.toggle_menu} />
              <br />
              <button
                className="button margin_bottom button_white"
                onClick={this.toggle_edit_project}
              >
                {dict["Edit"][language]}
              </button>
              <br />
              <button
                className="button margin_bottom button_white"
                onClick={this.toggle_push_member}
              >
                {dict?.["Collaboration"]?.[language]}
              </button>
              <br />
              <button
                className="button margin_bottom button_white"
                onClick={this.toggle_move_folder}
              >
                {dict?.["Move to folder"]?.[language]}
              </button>
              <br />
              <button
                className="button margin_bottom button_white"
                onClick={this.toggleDelete}
              >
                {dict["Delete"][language]}
              </button>
            </>
          ) : (
            <>
              <PINNED_BUTTON toggle_menu={this.toggle_menu} />
              <br />
              <button
                className="button margin_bottom button_white"
                onClick={this.toggle_push_member}
              >
                {dict["Collaboration"][language]}
              </button>
              <br />
              <button
                className="button margin_bottom button_white"
                onClick={this.toggle_move_folder}
              >
                {dict?.["Move to folder"]?.[language]}
              </button>
            </>
          )}
        </div>
      </Menu>
    );

    const modal_edit_content = modal_edit && (
      <Modal
        modalSize="medium"
        id="modal"
        isOpen={modal_edit}
        onClose={this.toggle_edit_project}
      >
        <div className="box-body">
          <EDIT_PROJECT
            toggle_edit_project={this.toggle_edit_project}
            title={title}
          />
        </div>
      </Modal>
    );

    const modal_delete_content = modal_delete && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_delete}
        onClose={this.toggleDelete}
      >
        <div className="box-body">
          <DeleteProject toggleDelete={this.toggleDelete} title={title} />
        </div>
      </Modal>
    );

    const modal_push_member_content = modal_push_member && (
      <Modal
        modalSize="medium"
        modalHeight="max"
        id="modal"
        isOpen={modal_push_member}
        onClose={this.toggle_push_member}
      >
        <div className="box-body">
          <PushMember title={title} />
        </div>
      </Modal>
    );

    const modal_move_folder_content = modal_move_folder && (
      <Modal
        modalSize="medium"
        id="modal"
        isOpen={modal_move_folder}
        onClose={this.toggle_move_folder}
      >
        <div className="box-body">
          <MOVE_PROJECT_TO_FOLDER
            toggle_move_folder={this.toggle_move_folder}
          />
        </div>
      </Modal>
    );

    const load_more_content = (
      <div style={{ marginTop: 24 }}>
        {this.props.project.loadMoreBtn ? (
          <button
            className="button_pill outline_black"
            onClick={() => this.set_load_more_project()}
            disabled={this.props.project.loadMoreBtnLoading}
          >
            {dict["Load More Project"][language]}
          </button>
        ) : (
          <p>{dict["All Projects Shown"][language]}</p>
        )}
      </div>
    );

    return (
      <div style={{ marginBottom: title !== "Pinned" ? "200px" : "25px" }}>
        {modal_menu_content}
        {modal_edit_content}
        {modal_delete_content}
        {modal_push_member_content}
        {modal_move_folder_content}
        {is_content_show && (
          <>
            <h3 className="text_big margin_bottom">
              {dict?.[title || "Projects"]?.[language]}
              {title === "Pinned" &&
                ` ${project_list_final?.length || 0} ${
                  dict["of 5 projects"][language]
                }`}
            </h3>
            {content}
            {title !== "Pinned" && load_more_content}
          </>
        )}
        {title === "Pinned" && (
          <hr
            style={{
              width: "100%",
              height: "4px",
              backgroundColor: "#a5a5a5",
              border: "none",
              borderRadius: "4px",
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  project: state.project,
});

export default connect(mapStateToProps, {
  getProjectList,
  setProject,
  createEmpty,
  openModal,
  set_value_project,
})(PROJECT_MODE_BOX);
